<template>
    <v-container fluid fill-height class="auth_page">
        <v-row justify="center">
            <v-card class="elevation-1">
                <v-container fluid class="pt-0 pb-1">
                    <AuthForm :header="$t('auth.reset_password_text')" :is_loading="loading"
                              @submit="submit">
                        <template slot="content">
                            <v-col cols="12">
                                <v-text-field id="password" name="password" type="password" class="custom_field"
                                              v-model="user.password" :label="$t('general.password')"
                                              prepend-inner-icon="lock" outlined clearable
                                              @input="$v.user.password.$touch()" @blur="$v.user.password.$touch()"
                                              :error-messages="fieldErrors('password')">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field id="password_confirmation" name="password_confirmation" type="password"
                                              class="custom_field" v-model="user.password_confirmation"
                                              prepend-inner-icon="lock" :label="$t('general.password_confirmation')"
                                              outlined clearable @input="$v.user.password_confirmation.$touch()"
                                              @blur="$v.user.password_confirmation.$touch()"
                                              :error-messages="fieldErrors('password_confirmation')">
                                </v-text-field>
                            </v-col>
                        </template>
                    </AuthForm>
                </v-container>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, minLength, sameAs} from 'vuelidate/lib/validators'
    import AuthForm from './../../components/AuthForm.vue'

    export default {
        components: {
            AuthForm
        },
        props: ['reset_token'],
        mixins: [validationMixin],
        validations: {
            user: {
                password: {
                    required,
                    minLength: minLength(6)
                },
                password_confirmation: {
                    required,
                    sameAs: sameAs('password')
                }
            }
        },
        data: self => ({
            user: {
                password: null,
                password_confirmation: null,
                token: self.reset_token
            },
            loading: false
        }),
        methods: {
            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.user[prop].$dirty) return errors;

                // add password rules
                if (prop === 'password') {
                    !this.$v.user[prop].minLength && errors.push(this.$tc('validation.min_length', 1, {min: this.$v.user[prop].$params.minLength.min}));
                }

                // add password confirmation rules
                if (prop === 'password_confirmation') {
                    !this.$v.user[prop].sameAs && errors.push(this.$tc('validation.same_as', 1, {field: 'password'}));
                }

                !this.$v.user[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // make API call to change user password
            async submit() {
                this.$v.user.$touch();

                // if data is valid
                if (!this.$v.user.$invalid) {
                    this.loading = true;

                    let response = await this.$store.dispatch('apiRequest', {
                        method: 'post', url: 'reset-password', data: this.user
                    });

                    this.loading = false;

                    // successful response
                    if (!response.response) {
                        this.clearForm();
                        this.$router.push('/login');
                    }
                }
            },
            // clear form data
            clearForm() {
                this.$v.user.$reset();

                for (let prop in this.user) {
                    this.user[prop] = (prop === 'token') ? this.reset_token : null;
                }
            }
        }
    }
</script>