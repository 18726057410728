<template>
    <v-form autocomplete="off" class="auth_form">
        <v-row dense>
            <v-col cols="12" class="auth_header_layer" align="center">
                <h3>{{header}}</h3>
            </v-col>
            <slot name="content"></slot>
            <v-col cols="12" class="auth_button_layer" align="center">
                <v-btn color="primary" dark class="custom_button" :loading="is_loading" @click="submit">
                    {{$t('general.submit')}}
                </v-btn>
            </v-col>
            <slot name="links"></slot>
        </v-row>
    </v-form>
</template>

<script>
    export default {
        props: {
            header: {type: String},
            is_loading: {type: Boolean}
        },
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>